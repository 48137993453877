<template>
  <div class="row">
    <div class="col-12 col-lg-5 c g">
        <div class="card card-body">
            <div class="form-group">
                <span>اختر الفصل</span>
                <select class="form-control" v-model="classroom">
                    <option value="">-- اختر --</option>
                    <option v-for="c in classes" :key="c._id" :value="c.classname + ',' + c.classroom">
                        {{ c.classname }} - {{ c.classroom }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="">اسم معلم المادة</label>
                <input type="text" class="form-control" placeholder="اكتب هنا..." v-model="teacher">
            </div>
            <div class="form-group">
                <label for="">اسم مدير المدرسة</label>
                <input type="text" class="form-control" placeholder="اكتب هنا..." v-model="admin">
            </div>
            <div class="form-group">
                <label for="">الفصل الدراسي</label>
                <select class="form-control bg-white" v-model="semster">
                    <option value="الاول">الاول</option>
                    <option value="الثاني">الثاني</option>
                    <option value="الثالث">الثالث</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">المادة / الكشف</label>
                <select class="form-control bg-white" v-model="subject">
                    <option value="">-- اختر --</option>
                    <optgroup label="المواد">
                        <option v-for="s in subjects" :key="s" :value="s">{{ s }}</option>
                    </optgroup>
                    <optgroup label="كشوفات اخرى">
                        <option value="1">متابعة الغياب اليومي</option>
                        <option value="2">متابعة الغياب الاسبوعي</option>
                    </optgroup>
                </select>
            </div>
            <div class="col-12 text-center g" @click="view()">
                <button class="btn btn-primary">
                    عرض الكشف
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            classes: [],
            classroom: "",
            semster: "الثاني",
            teacher: "",
            admin: "",
            subjects: [],
            students: [],
            subject: ""
        }
    },
    created(){
        if(!checkPer("mentors")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        var t = `الدراسات الاجتماعية
التفكير الناقد
القرآن الكريم والدراسات الإسلامية
التربية البدنية والدفاع عن النفس
الرياضيات
Super Goal
العلوم
الحاسب وتقنية المعلومات
التربية الفنية
لغتي الخالدة
المهارات الحياتية والأسرية`
        g.subjects = t.split("\n")
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    a.classrooms.forEach(function(b){
                        g.classes.push({
                            classname: a.title,
                            classroom: b
                        })
                    })
                })
            }
        }).fail(function(){
        })
        $.post(api + '/user/students/list', {
            jwt: g.user.jwt,
            page: 1,
            perpage: 11111
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    methods: {
        view(){
            var g = this;
            if(!g.classroom.split(",")[1]){
                return alert("قم باختيار الفصل")
            }
            if(g.subject == ""){
                return alert("قم باختيار المادة او الكشف")
            }
            if(g.subject == '1' || g.subject == '2'){
                g.print12(g.subject)
            }else{
                g.print()
            }
        },
        print12(t){
            var g = this;
            var newWin= window.open("");
            var body = ""
            if(t == '1'){
                body = `<table>
            <tbody>
                <tr>
                    <td>
                        م
                    </td>

                    <td>
                        اسم الطالب
                    </td>
                    <td>
                        نوع الحضور ( غ ) غائب
                    </td>
                </tr>`
                var index = 0;
                g.students.forEach(function(a){
                    if(g.classroom == a.classname + ',' + a.classroom){
                    index = index + 1
                    body = body + `
                        <tr style="background:#fff; text-align: right">
                            <td>${index}</td> <td  style="  white-space: nowrap;border-top: 1px black solid;">${a.name}</td>
                        <td><input style='border:none;width:90%;border-top: 1px solid #fff'></td>
                        </tr>
                        `
                    }
                })
                body = body + `
            </tbody>
        </table>`
            }
            if(t == '2'){
                body = `<table>
            <tbody>
                <tr>
                    <td>
                        م
                    </td>
                    <td>
                        اسم الطالب
                    </td>
                    <td style='text-align:center'>
                        الأحد
                        <br>
                        الحصة
                        (<input type="text" style="border:none;height:20px;font-size: 18px; width: 60px"
                            placeholder="">)
                    </td>
                    <td style='text-align:center'>
                        الاثنين
                        <br>
                        الحصة
                        (<input type="text" style="border:none;height:20px;font-size: 18px; width: 60px"
                            placeholder="">)
                    </td>
                    <td style='text-align:center'>
                        الثلاثاء
                        <br>
                        الحصة
                        (<input type="text" style="border:none;height:20px;font-size: 18px; width: 60px"
                            placeholder="">)
                    </td>
                    <td style='text-align:center'>
                        الاربعاء
                        <br>
                        الحصة
                        (<input type="text" style="border:none;height:20px;font-size: 18px; width: 60px"
                            placeholder="">)
                    </td>
                    <td style='text-align:center'>
                        الخميس
                        <br>
                        الحصة
                        (<input type="text" style="border:none;height:20px;font-size: 18px; width: 60px"
                            placeholder="">)
                    </td>
                </tr>`
                var index = 0;
                g.students.forEach(function(a){
                    if(g.classroom == a.classname + ',' + a.classroom){
                    index = index + 1
                    body = body + `
                        <tr style="background:#fff; text-align: right">
                        <td>${index}</td> <td  style="white-space: nowrap;  border-top: 1px black solid;">${a.name}</td>
                    <td><input style='border:none;width:90%;border-top: 1px solid #fff'></td>
                    <td><input style='border:none;width:90%;border-top: 1px solid #fff'></td>
                    <td><input style='border:none;width:90%;border-top: 1px solid #fff'></td>
                    <td><input style='border:none;width:90%;border-top: 1px solid #fff'></td>
                    <td><input style='border:none;width:90%;border-top: 1px solid #fff'></td>
                    </tr>
                        `
                    }
                })
                body = body + `
            </tbody>
        </table>`
            }
            newWin.document.write(`
<!DOCTYPE html>
<html lang="ar" dir="rtl">

<head>
    <meta charset="UTF-8">
    <link rel="icon" href="/assets/custom/img/logo.png" type="image/png">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>
        التحضير الذكي | www.tahdir.net
    </title>
    <style>
        @font-face {
            font-family: 'myFirstFont';
            src: url("/calibril.ttf");
        }

        table,
        td,
        th {
            border: 1px solid;
            padding: 1px !important
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        * {
            font-family: "myFirstFont" !important;
            font-weight: normal !important;
        }

        .content {
            position: relative;
            top: -120px;
            width: 90%;
            margin: 0px auto;
        }

        .copy {
            position: absolute;
            z-index: 999;
            top: 0px;
            left: 0px;
        }

        .print {
            position: absolute;
            top: 100px;
            left: 0px;
            z-index: 9999 !important;
        }

        button {
            border: 2px solid #4CAF50;
            /* Green */
            padding: 0px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            margin: 4px 2px;
            transition-duration: 0.4s;
            cursor: pointer;
            width: 100px;
            background-color: #4CAF50;
            color: white;
        }

        button:hover {
            background-color: #ff05c9;
            /* Green */
            color: white;
        }

        .content img {
            position: absolute;
            top: 0px;
            max-height: 80vh
        }

        @media  print {
            .print {
                display: none
            }
        }

        @page  {
            margin: 0;
        }
    </style>
</head>

<body>
    <div class="print" onclick="window.print()">
        <button>طباعة الآن</button>
    </div>
    <header style="position: relative;top:-80px">
        <div style='width: 100%; padding: 20px;'>
            <div style='width: 40%; text-align: center; display: inline-block; position: relative;top:-5px !important'>
                <h4>
                    <img src="https://cdn-static.brmja.com/storage/scrapped/6319866ea8712498504411.jpg"
                        style='width: 20%' alt="">
                    <br>
                    وزارة التعليم
                    <br>
                    <div id="school_name">${g.user.name}</div>
                    </h5>
            </div>
            <div style='width: 40%; text-align: center; display: inline-block; position: relative; top: 5px'>
                <br><br><br><br>
                <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 40%'>
            </div>
        </div>
    </header>
    <div class="content">
        <center>
            <div id="title">
                ${t == '1' ? '<h3>كشف الغياب اليومي</h3>' : '<h3>متابعة الغياب الاسبوعي</h3>'}
            </div>
            <div id="title">
                <h3>الصف: ${g.classroom.split(",")[0]}
                    &nbsp; &nbsp;&nbsp;الفصل: ${g.classroom.split(",")[1]}
                    &nbsp; &nbsp;&nbsp;اليوم: <input type="text"
                        style="border:none;height:30px;font-size: 18px; width: 60px" placeholder="..."> &nbsp;التاريخ:
                    <input type="text" style="border:none;height:30px;font-size: 18px; width: 100px"
                        placeholder="..." value=" /  / 1446">&nbsp;&nbsp;&nbsp;الفصل الدراسي <input
                        type="text" style="border:none;height:30px;font-size: 18px; width: 60px" placeholder="..." value="${g.semster}">

                </h3>
            </div>
        </center>
        ${body}
    </div>
</body>

</html>
            `);
            newWin.document.close()
        },
        print(){
            var g = this;
            var newWin= window.open("");
            var trbiafniaapk = `<tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>تطبيقي</th>
 </tr>
 <tr style="background:#ddd">
    <th>15</th>
    <th>20</th>
    <th>20</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>100</th>
 </tr>`;

        var trbiafniafady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;

        var rqmiardtr = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>بحوث مشاريع</th>
    <th>أنشطة ومشاركة</th>
    <th>تطبيقات عملية</th>
    <th>اختبار قصير</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>20</th>
    <th>100</th>
 </tr>`;

        var rqmiafady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;

        var drasaatrowstop = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>15</th>
    <th>20</th>
    <th>20</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>100</th>
 </tr>`;
        var drasaatrowstopfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
        var osriarowst = `<tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>20</th>
    <th>20</th>
    <th>20</th>
    <th>20</th>
    <th>20</th>
    <th>--</th>
    <th>100</th>
 </tr>`;
        var osriarowstfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
        var loomtoptr = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
        var loomtoptrfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
        var nafstoptr = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
        var nafstoptrfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
        var wcantoptrs = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>تطبيقي</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>5</th>
    <th>10</th>
    <th>15</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
       var  wcantoptrsfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
       var  tagwidtoptrd = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="4">القرآن الكريم</th>
    <th colspan="4">التجويد</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>صحة القراءة</th>
    <th>الترتيل</th>
    <th>التجويد</th>
    <th>الحفظ</th>
    <th>واجبات</th>
    <th>تطبيقات</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>40</th>
    <th>5</th>
    <th>5</th>
    <th>20</th>
    <th>5</th>
    <th>5</th>
    <th>5</th>
    <th>15</th>
    <th>100</th>
 </tr>`;
      var   tagwidtoptrdfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
       var  islamiatoptrs = `<tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
       var  islamiatoptrsfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
      var   ryadiattoptrs = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
      var   ryadiattoptrsfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
       var  gmilatoptrf = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>5</th>
    <th>10</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
     var   gmilatoptrffady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
     var    lo8atytoptrs = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>5</th>
    <th>10</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
       var  lo8atytoptrsfady = `  <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
       var  krimtoptrs = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th style="width:12%">صحة القراءة</th>
    <th style="width:12%">الترتيل</th>
    <th style="width:12%">تطبيق التجويد</th>
    <th style="width:12%">الحفظ</th>
    <th style="width:12%">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>45</th>
    <th>10</th>
    <th>10</th>
    <th>35</th>
    <th>100</th>
 </tr>`;
      var   krimtoptrsfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
    var trfady = "", trfortoprows = "";
        var subject = g.subject
        if (subject.indexOf("فنية") !== -1) {
            trfady = trbiafniafady;
            trfortoprows = trbiafniaapk;
        } else if (subject.indexOf("رقمية") !== -1) {
            trfady = rqmiafady;
            trfortoprows = rqmiardtr;
        } else if (subject.indexOf("جتماعية") !== -1) {
            trfady = drasaatrowstopfady;
            trfortoprows = drasaatrowstop;
        } else if (subject.indexOf("سرية") !== -1) {
            trfady = osriarowstfady;
            trfortoprows = osriarowst;
        } else if (subject.indexOf("علوم") !== -1) {
            trfady = loomtoptrfady;
            trfortoprows = loomtoptr;
        } else if (subject.indexOf("نفس") !== -1) {
            trfady = nafstoptrfady;
            trfortoprows = nafstoptr;
        } else if ((subject.indexOf("We") !== -1) || (subject.indexOf("WE") !== -1) || (subject.indexOf("we") !== -1)) {
            trfady = wcantoptrsfady;
            trfortoprows = wcantoptrs;
        } else if (subject.indexOf("تجويد") !== -1) {
            trfady = tagwidtoptrdfady;
            trfortoprows = tagwidtoptrd;
        } else if (subject.indexOf("سلام") !== -1) {
            trfady = islamiatoptrsfady;
            trfortoprows = islamiatoptrs;
        } else if (subject.indexOf("رياض") !== -1) {
            trfady = ryadiattoptrsfady;
            trfortoprows = ryadiattoptrs;
        } else if (subject.indexOf("جميلة") !== -1) {
            trfady = gmilatoptrffady;
            trfortoprows = gmilatoptrf;
        } else if ((subject.indexOf('لغتي') !== -1) && (subject.indexOf('الجميلة') == -1)) {
            trfady = lo8atytoptrsfady;
            trfortoprows = lo8atytoptrs;
        } else if ((subject.indexOf('الكريم') !== -1) && (subject.indexOf('وتجويده') == -1)) {
            trfady = krimtoptrsfady;
            trfortoprows = krimtoptrs;
        } else {
            trfady = ryadiattoptrsfady;
            trfortoprows = ryadiattoptrs;
        }
        var data = "";

        var index = 0;
        g.students.forEach(function(a){
            if(g.classroom == a.classname + ',' + a.classroom){
            index = index + 1
            data = data + `<tr style="background:#fff; text-align: right">
                    <td>${index}</td> <td  style="  border-top: 1px black solid;">${a.name}</td>
                    ${trfady}
                </tr>`
            }
        })
            newWin.document.write(`
            <!DOCTYPE html>
<html lang="ar" dir="rtl">

<head>
    <meta charset="UTF-8">
    <link rel="icon" href="/assets/custom/img/logo.png" type="image/png">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>
        التحضير الذكي | www.tahdir.net
    </title>
    <style>
        @font-face {
            font-family: 'myFirstFont';
            src: url("/calibril.ttf");
        }

        table,
        td,
        th {
            border: 1px solid;
            padding: 1px !important
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        * {
            font-family: "myFirstFont" !important;
            font-weight: normal !important;
        }

        .content {
            position: relative;
            top: -120px;
            width: 90%;
            margin: 0px auto;
        }

        .copy {
            position: absolute;
            z-index: 999;
            top: 0px;
            left: 0px;
        }

        .print {
            position: absolute;
            top: 100px;
            left: 0px;
            z-index: 9999 !important;
        }

        button {
            border: 2px solid #4CAF50;
            /* Green */
            padding: 0px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            margin: 4px 2px;
            transition-duration: 0.4s;
            cursor: pointer;
            width: 100px;
            background-color: #4CAF50;
            color: white;
        }

        button:hover {
            background-color: #ff05c9;
            /* Green */
            color: white;
        }

        .content img {
            position: absolute;
            top: 0px;
            max-height: 80vh
        }

        @media  print {
            .print {
                display: none
            }
        }

        @page  {
            margin: 0;
        }
    </style>
</head>

<body>
    <div class="print" onclick="window.print()">
        <button>طباعة الآن</button>
    </div>
    <header style="position: relative;top:-110px">
        <div style='width: 100%; padding: 20px;'>
            <div style='width: 30%; text-align: center; display: inline-block; position: relative;top:-20px !important'>
                <h4>
                    <img src="https://cdn-static.brmja.com/storage/scrapped/6319866ea8712498504411.jpg"
                        style='width: 50%' alt="">
                    <br>
                    وزارة التعليم
                    <br>
                    <div id="school_name">${g.user.name}</div>
                    </h5>
            </div>
            <div style='width: 30%; text-align: center; display: inline-block; position: relative; top: 5px'>
                <br><br><br><br>
                <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 80%'>
            </div>
            <div
                style='width: 30%; text-align: center; display: inline-block; position: relative; top: 0px !important; text-align: right'>
                <h3>
                    العام الدراسي: &nbsp;&nbsp; / &nbsp;&nbsp; / &nbsp;144<br>
                    الفصل الدراسي: ${g.semster}<br>
                    الفصل: ${g.classroom.replace(',', ' - ')}<br>
                    المادة: ${g.subject}
                </h3>
            </div>
        </div>
    </header>
    <div class="content">
        <center>
            <div id="title"></div>
        </center>
        <br>
        <table>
            <tbody>${trfortoprows}${data}</tbody>
        </table>
        <h4>
            <span id="teacher">معلم المادة: 
                ${g.teacher}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> مدير المدرسة: ${g.admin}
            <span></span> <br><small style="float:left">
             
        </h4>
    </div>
    </body></html>
            `);
            newWin.document.close()
        }
    }
}
</script>

<style>

</style>